import Vue from 'vue'
import App from './App'
import router from './router'
import ViewUI from 'view-design'
import BaiduMap from 'vue-baidu-map'
import { Drawer } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import style
import 'view-design/dist/styles/iview.css'
import './assets/less/index.less'
import img from './lib/img'
import utils from './lib/utils'
import echarts from 'echarts'
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
Vue.use(Drawer)

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '9Ctna1FKTFuVdgifCtSEFYxZFuIXkedO'
})
Vue.prototype.$echart = echarts
Vue.prototype.$echarts = function (el) {
  return echarts.init(el, null, { renderer: 'svg' })
}
Vue.prototype.$images = img
Vue.config.productionTip = false
Vue.use(ViewUI)
Vue.use(dataV)
Vue.use(utils)
new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
