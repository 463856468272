<template>
  <div style="height: 100%">
    <div v-if="loading === true" class="out" style="height: 100%">
      <div class="fade-in">
        <div class="container">
          <div class="one common"></div>
          <div class="two common"></div>
          <div class="three common"></div>
          <div class="four common"></div>
          <div class="five common"></div>
          <div class="six common"></div>
          <div class="seven common"></div>
          <div class="eight common"></div>
        </div>
        <!--        <div class="bar">
                  <div class="progress"></div>
                </div>-->
      </div>
    </div>
    <div v-else class="bounceInDown animated" style="height: 100%">
      <div class="header-home">
        <Row type="flex" justify="center" align="middle">
          <Col span="8">
            <div class="selectRange">
              <Menu mode="horizontal" @on-select="(name) => $router.push(name)" :active-name="$route.name">
                <MenuItem name="page1"> 秦皇岛一卡通 </MenuItem>
                <!-- <MenuItem name="page2"> 承德 </MenuItem> -->
                <MenuItem name="page3"> 迁安一卡通 </MenuItem>
                <MenuItem name="page4"> 葫芦岛一卡通 </MenuItem>
                <!-- <MenuItem name="page5"> 智慧养老 </MenuItem> -->
                <MenuItem name="page6"> 合作伙伴 </MenuItem>
              </Menu>
            </div>
          </Col>
          <Col span="8">
            <div class="header-home-title">
              <Row :gutter="10" type="flex" justify="center" align="middle">
                <Col flex="auto" class-name="img-title">
                  <img src="../assets/images/logo.png" style="width: 35px; height: 35px; margin-right: 10px" />
                  一卡通大数据监控平台
                </Col>
              </Row>
            </div>
          </Col>
          <Col span="8" style="float: right">
            <div class="selectRange">
              <Menu mode="horizontal" @on-select="(name) => $router.push(name)" :active-name="$route.name">
                <MenuItem name="page5"> 智慧养老 </MenuItem>
                <MenuItem name="page7"> 智慧洗护 </MenuItem>
                <!-- <MenuItem name="page2"> 承德 </MenuItem> -->
                <MenuItem name="page8"> 电子商城 </MenuItem>
                <MenuItem @click.native="showDrw = true"> 公司简介 </MenuItem>
              </Menu>
            </div>
            <!--            <div class="selectRange">
              <Menu mode="horizontal" @on-select="handleSelect" :active-name="activeName">
                <MenuItem name="day">
                  昨日
                </MenuItem>
                <MenuItem name="week">
                  近一周
                </MenuItem>
                <MenuItem name="month">
                  近一月
                </MenuItem>
                <Submenu name="4">
                  <template slot="title">
                    <Icon type="ios-search-outline" size="24" color="#60C2D4"/>
                  </template>
                  <MenuItem name="filter">筛选</MenuItem>
                </Submenu>

              </Menu>
            </div>-->
          </Col>
        </Row>
      </div>
      <Modal v-model="modal" title="选择时间" :mask-closable="false" @on-ok="getMonthBetween(startTime, endTime)">
        <DatePicker @on-change="pickStartDate" :options="optionStart" type="date" placeholder="选择开始日期" style="width: 200px"></DatePicker>
        <span style="padding: 0 20px; color: #75deef">至</span>
        <DatePicker @on-change="pickEndDate" :options="optionEnd" type="date" placeholder="选择结束日期" style="width: 200px"></DatePicker>
      </Modal>
      <div class="page">
        <router-view v-if="flag" :selectRangeDate="selectRangeDate"></router-view>
      </div>

      <drawer :showDrw="showDrw" @close="closeDrw" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import drawer from './components/drawer/index.vue'
export default {
  name: '',
  components: {
    drawer
  },

  data() {
    return {
      showDrw: false,
      loading: true,
      activeName: 'month', // 默认显示近一月
      modal: false,
      flag: false,
      selectRangeDate: [],
      dataSource: [],
      startTime: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      endTime: moment().format('YYYY-MM-DD'),
      optionStart: {
        disabledDate(date) {
          // 禁止选择今天之后的日期
          return date && date.valueOf() > Date.now() - 86400000
        }
      },
      optionEnd: {},
      resizeFn: null
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeFn)
    this.handleSelect(this.activeName) // 默认显示近一个月
    this.$nextTick(() => {
      this.loading = true
      setInterval(() => {
        this.loading = false
      }, 3000)
    })
  },
  methods: {
    closeDrw(close) {
      console.log(close)

      this.showDrw = close
    },
    pickStartDate(date) {
      // 选择开始时间的回调
      this.startTime = date
      this.optionEnd = {
        disabledDate(d) {
          // 禁止选择开始时间之前的日期
          return d && d.valueOf() < new Date(date).valueOf() - 86400000
        }
      }
    },
    pickEndDate(date) {
      // 选择结束时间的回调
      this.endTime = date
    },
    getMonthBetween(start, end) {
      // 获取开始时间和结束时间之内的所有月份
      this.selectRangeDate = []
      let s = start.split('-') // 字符串装换数组
      let e = end.split('-')
      let date = new Date()
      let min = date.setFullYear(s[0], s[1] - 1) // 设置最小时间
      let max = date.setFullYear(e[0], e[1] - 1) // 设置最大时间
      let curr = min
      while (curr <= max) {
        // 循环添加月份
        var month = curr.getMonth()
        var arr = [curr.getFullYear(), month + 1]
        this.selectRangeDate.push(arr)
        curr.setMonth(month + 1)
      }
    },
    getDays(day) {
      // 获取天数
      let arr = []
      for (let i = -day; i < 0; i++) {
        // 循环添加天数
        let today = new Date() // 获取今天
        let targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * i
        today.setTime(targetday_milliseconds) //设置i天前的时间
        let tYear = today.getFullYear()
        let tMonth = today.getMonth()
        let tDate = today.getDate()
        let date = [tYear, tMonth + 1, tDate]
        arr.push(date)
      }
      return arr
    },
    handleSelect(name) {
      switch (name) {
        case 'day':
          break
        case 'week':
          this.selectRangeDate = this.getDays(7) // 获取近一周的天数
          this.flag = true

          break
        case 'month':
          this.selectRangeDate = this.getDays(30) // 获取近一个月的天数
          this.flag = true
          break
        case 'filter':
          this.modal = true
          break
        default:
          break
      }
    }
  }
}
</script>
<style scoped>
/*base code*/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

/*the animation definition*/
@-webkit-keyframes bounceInDown {
  0%,
  100%,
  60%,
  75%,
  90% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes bounceInDown {
  0%,
  100%,
  60%,
  75%,
  90% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    -ms-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    -ms-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    -ms-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  100% {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}
.container {
  width: 40vw;
  height: 40vw;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
}

.common {
  height: 5vw;
  max-height: 100%;
  overflow: auto;
  width: 2vw;
  margin: auto;
  max-width: 100%;
  position: absolute;
  /* background-color: ; */
  border-radius: 0vw 10vw 0vw 10vw;
  box-shadow: inset 0vw 0vw 0vw 0.1vw #e645d0, 0vw 0vw 1.5vw 0vw #e645d0;
}

.one {
  transform: rotate(45deg);
  left: 0;
  right: 0;
  top: 0;
  bottom: 7.5vw;
}

.two {
  transform: rotate(90deg);
  left: 5.5vw;
  right: 0;
  top: 0;
  bottom: 5.5vw;
}

.three {
  transform: rotate(135deg);
  left: 7.5vw;
  right: 0;
  top: 0;
  bottom: 0;
}

.four {
  transform: rotate(180deg);
  left: 5.5vw;
  right: 0;
  top: 5.5vw;
  bottom: 0;
}

.five {
  transform: rotate(225deg);
  left: 0;
  right: 0;
  top: 7.5vw;
  bottom: 0;
}

.six {
  transform: rotate(270deg);
  left: 0;
  right: 5.5vw;
  top: 5.5vw;
  bottom: 0;
}

.seven {
  transform: rotate(315deg);
  left: 0;
  right: 7.5vw;
  top: 0;
  bottom: 0;
}

.eight {
  transform: rotate(360deg);
  left: 0;
  right: 5.5vw;
  top: 0;
  bottom: 5.5vw;
}

.bar {
  width: 12vw;
  height: 0.25vw;
  position: absolute;
  left: 0;
  right: 0;
  top: 16vw;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  background: #e645d0;
}

.progress {
  width: 12vw;
  height: 0.5vw;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  background: #17e1e6;
}

.one {
  animation: one 1s ease infinite;
  -moz-animation: one 1s ease infinite;
  /* Firefox */
  -webkit-animation: one 1s ease infinite;
  /* Safari and Chrome */
  -o-animation: one 1s ease infinite;
  /* Opera */
}

@keyframes one {
  0%,
  100% {
  }
  50% {
    /* background: ; */
    box-shadow: inset 0vw 0vw 0vw 0.1vw #17e1e6, 0vw 0vw 1.5vw 0vw #17e1e6;
  }
}

.two {
  animation: two 1s 0.125s ease infinite;
  -moz-animation: two 1s 0.125s ease infinite;
  /* Firefox */
  -webkit-animation: two 1s 0.125s ease infinite;
  /* Safari and Chrome */
  -o-animation: two 1s 0.125s ease infinite;
  /* Opera */
}

@keyframes two {
  0%,
  100% {
  }
  50% {
    /* background: ; */
    box-shadow: inset 0vw 0vw 0vw 0.1vw #17e1e6, 0vw 0vw 1.5vw 0vw #17e1e6;
  }
}

.three {
  animation: three 1s 0.25s ease infinite;
  -moz-animation: three 1s 0.25s ease infinite;
  /* Firefox */
  -webkit-animation: three 1s 0.25s ease infinite;
  /* Safari and Chrome */
  -o-animation: three 1s 0.25s ease infinite;
  /* Opera */
}

@keyframes three {
  0%,
  100% {
  }
  50% {
    /* background: ; */
    box-shadow: inset 0vw 0vw 0vw 0.1vw #17e1e6, 0vw 0vw 1.5vw 0vw #17e1e6;
  }
}

.four {
  animation: four 1s 0.375s ease infinite;
  -moz-animation: four 1s 0.375s ease infinite;
  /* Firefox */
  -webkit-animation: four 1s 0.375s ease infinite;
  /* Safari and Chrome */
  -o-animation: four 1s 0.375s ease infinite;
  /* Opera */
}

@keyframes four {
  0%,
  100% {
  }
  50% {
    /* background: ; */
    box-shadow: inset 0vw 0vw 0vw 0.1vw #17e1e6, 0vw 0vw 1.5vw 0vw #17e1e6;
  }
}

.five {
  animation: five 1s 0.5s ease infinite;
  -moz-animation: five 1s 0.5s ease infinite;
  /* Firefox */
  -webkit-animation: five 1s 0.5s ease infinite;
  /* Safari and Chrome */
  -o-animation: five 1s 0.5s ease infinite;
  /* Opera */
}

@keyframes five {
  0%,
  100% {
  }
  50% {
    /* background: ; */
    box-shadow: inset 0vw 0vw 0vw 0.1vw #17e1e6, 0vw 0vw 1.5vw 0vw #17e1e6;
  }
}

.six {
  animation: six 1s 0.625s ease infinite;
  -moz-animation: six 1s 0.625s ease infinite;
  /* Firefox */
  -webkit-animation: six 1s 0.625s ease infinite;
  /* Safari and Chrome */
  -o-animation: six 1s 0.625s ease infinite;
  /* Opera */
}

@keyframes six {
  0%,
  100% {
  }
  50% {
    /* background: ; */
    box-shadow: inset 0vw 0vw 0vw 0.1vw #17e1e6, 0vw 0vw 1.5vw 0vw #17e1e6;
  }
}

.seven {
  animation: seven 1s 0.75s ease infinite;
  -moz-animation: seven 1s 0.75s ease infinite;
  /* Firefox */
  -webkit-animation: seven 1s 0.75s ease infinite;
  /* Safari and Chrome */
  -o-animation: seven 1s 0.75s ease infinite;
  /* Opera */
}

@keyframes seven {
  0%,
  100% {
  }
  50% {
    /* background: ; */
    box-shadow: inset 0vw 0vw 0vw 0.1vw #17e1e6, 0vw 0vw 1.5vw 0vw #17e1e6;
  }
}

.eight {
  animation: eight 1s 0.875s ease infinite;
  -moz-animation: eight 1s 0.875s ease infinite;
  /* Firefox */
  -webkit-animation: eight 1s 0.875s ease infinite;
  /* Safari and Chrome */
  -o-animation: eight 1s 0.875s ease infinite;
  /* Opera */
}

@keyframes eight {
  0%,
  100% {
  }
  50% {
    /* background: ; */
    box-shadow: inset 0vw 0vw 0vw 0.1vw #17e1e6, 0vw 0vw 1.5vw 0vw #17e1e6;
  }
}

.container {
  animation: container 5s linear infinite;
  -moz-animation: container 5s linear infinite;
  /* Firefox */
  -webkit-animation: container 5s linear infinite;
  /* Safari and Chrome */
  -o-animation: container 5s linear infinite;
  /* Opera */
}

@keyframes container {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.progress {
  animation: progress 15s ease;
  -moz-animation: progress 15s ease;
  /* Firefox */
  -webkit-animation: progress 15s ease;
  /* Safari and Chrome */
  -o-animation: progress 15s ease;
  /* Opera */
}

@keyframes progress {
  0% {
    left: -24vw;
  }
  10% {
    left: -20vw;
  }
  30% {
    left: -16vw;
  }
  50% {
    left: -12vw;
  }
  65% {
    left: -10vw;
  }
  80% {
    left: -4vw;
  }
  100% {
    left: 0;
  }
}

.fade-in {
  animation: fade-in 2s ease;
  -moz-animation: fade-in 2s ease;
  /* Firefox */
  -webkit-animation: fade-in 2s ease;
  /* Safari and Chrome */
  -o-animation: fade-in 2s ease;
  /* Opera */
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.out {
  animation: out 2s 15s ease;
  -moz-animation: out 2s 15s ease;
  /* Firefox */
  -webkit-animation: out 2s 15s ease;
  /* Safari and Chrome */
  -o-animation: out 2s 15s ease;
  /* Opera */
}

@keyframes out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
<style lang="less">
.ivu-modal {
  .ivu-modal-content {
    background: #071332;

    .ivu-modal-header {
      border-bottom: 1px solid #1a3c58;

      .ivu-modal-header-inner {
        color: #75deef;
      }
    }

    .ivu-modal-body {
      text-align: center;

      .ivu-icon {
        color: #75deef;
      }

      .ivu-modal-confirm-body {
        padding-left: 0;
        color: #75deef;
      }

      .ivu-input {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #1a3c58;
        color: #75deef;

        &::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: #75deef;
        }

        &::-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #75deef;
        }

        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #75deef;
        }

        &::-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #75deef;
        }
      }

      .ivu-picker-panel-body {
        background: #071332;

        .ivu-date-picker-header {
          color: #75deef;
          border-bottom: 1px solid #1a3c58;
        }

        .ivu-date-picker-cells-cell {
          color: #75deef;

          &:hover em {
            background: #1a3c58;
          }
        }

        .ivu-date-picker-cells-cell-disabled {
          background: rgba(0, 0, 0, 0);
          color: #eee;
        }

        .ivu-date-picker-cells-focused em {
          box-shadow: 0 0 0 1px #1a3c58 inset;

          &::after {
            background: #1a3c58;
          }
        }
      }
    }

    .ivu-modal-footer {
      border-top: 1px solid #1a3c58;

      .ivu-btn-primary {
        color: #75deef;
        background: #1a3c58;
      }

      .ivu-btn-text {
        color: #ddd;

        &:hover {
          color: #75deef;
          background: #1a3c58;
        }
      }
    }
  }
}

.header-home {
  height: 80px;
  /* background: #03044A;*/
  /*    display: flex;
    justify-content: space-between;
    align-items: center;*/
  &-title {
    text-align: center;
    color: #75deef;
    font-size: 30px;
    letter-spacing: 10px;
    .img-title {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .selectRange {
    height: 100%;

    .ivu-menu-horizontal {
      background: rgba(255, 255, 255, 0);

      &::after {
        height: 0;
      }

      .ivu-menu-item-active {
        border-bottom: 2px solid #264e5e;
      }

      .ivu-menu-item,
      .ivu-menu-submenu {
        color: #75deef;
        font-size: 18px;
        &:hover {
          border-bottom: 2px solid #264e5e;
        }
      }

      .ivu-select-dropdown {
        background: #09102e;

        .ivu-menu-item {
          color: #75deef;

          &:hover {
            border-bottom: 2px solid #264e5e;
            background-color: rgba(255, 255, 255, 0);
          }
        }
      }

      .ivu-menu-submenu-title {
        i {
          margin-right: 0;
        }

        .ivu-icon-ios-arrow-down {
          display: none;
        }
      }
    }
  }
}

.page {
  height: calc(~'100% - 80px');
}
</style>
