<template>
  <div class="page">
    <el-drawer
      title="秦皇岛旅游一卡通集团"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      size="100%"
      :modal="false"
      :append-to-body="true"
      custom-class="drw"
    >
      <div class="box">
        <div class="left">
          <img src="../../../assets/images/logo2.png" class="logo" />
          <div class="text-box">
            <p>
              秦皇岛旅游一卡通集团成立于2005年,是一家集景区建设、旅游年卡、软件开发、大数据
              平台运营、自媒体运营、教育培训的科技集团公司。公司核心团队汇聚了企业管理、互联
              网营销、行业应用研发、大数据分析等各大领域精英,不断整合行业资源优势,与全国知名
              高校合作,提升技术研发实力与运营管理能力,为客户提供最优质的产品体验与服务。
            </p>
          </div>
        </div>
        <div class="right">
          <img src="../../../assets/images/bioaoti13.png" class="zongzhi" />
          <div class="content" :class="{ 'fade-enter': isShow }">
            <img src="../../../assets/images/zongzhi3.png" class="zz-img" :class="{ 'fade-enter': isShow }" />
            <div class="zz-text">
              <div>服务</div>
              <div>宗旨</div>
            </div>

            <img src="../../../assets/images/zongzhi2.png" class="zz-img-two" :class="{ 'fade-enter': isShow }" />
          </div>
        </div>
      </div>
      <div class="bottom-box">
        <div class="left-box">
          <img src="../../../assets/images/bioaoti12.png" class="lc-img" />

          <div class="lc-box">
            <img src="../../../assets/images/licheng1.png" class="lc-img-one" :class="{ 'fade-enter': isShow }" />
            <img src="../../../assets/images/licheng2.png" class="lc-img-two" :class="{ 'fade-enter': isShow }" />
            <img src="../../../assets/images/licheng4.png" class="lc-img-three" :class="{ 'fade-enter': isShow }" />
            <img src="../../../assets/images/licheng6.png" class="lc-img-four" :class="{ 'fadeLeft-enter': isShow }" />
            <img src="../../../assets/images/licheng3.png" class="lc-img-five" :class="{ 'fade-enter': isShow }" />
            <img
              src="../../../assets/images/licheng5.png"
              class="lc-img-six"
              :class="{ 'fade-enter': isShow }"
              :style="{ 'animation-delay': isShow ? '1s' : '0.6s' }"
            />
            <div class="box1 common-box">
              <p class="year">2005</p>
              <p class="nr">公司成立</p>
            </div>
            <div class="box2 common-box">
              <div class="year">2011</div>
              <div class="nr">发行</div>
              <div class="nr-two">秦皇岛一卡通</div>
            </div>
            <div class="box3 common-box">
              <div class="year">2012</div>
              <div class="nr">发行</div>
              <div class="nr-two">智慧旅游年票</div>
            </div>
            <div class="box4 common-box">
              <div class="year">2014</div>
              <div class="nr">整合</div>
              <div class="nr-two">智慧旅游大数据平台</div>
            </div>
            <div class="box5 common-box">
              <div class="year">2016</div>
              <div class="nr">开展</div>
              <div class="nr-two">软件开发业务</div>
            </div>
            <div class="box6 common-box">
              <div class="year">2017</div>
              <div class="nr">会员增长至40万人</div>
            </div>
            <div class="box7 common-box">
              <div class="year">2019</div>
              <div class="nr">合作景区已达34家</div>
            </div>
            <div class="box8 common-box">
              <div class="year">2020</div>
              <div class="nr">发行</div>
              <div class="nr-two">秦皇岛文化和旅游惠民卡</div>
            </div>
            <div class="box9 common-box">
              <div class="year">2021</div>
              <div class="nr">发行</div>
              <div class="nr-two">长城主题文化探访之路十年卡</div>
            </div>
            <div class="box10 common-box">
              <div class="year">2023</div>
              <div class="nr">发行</div>
              <div class="nr-two">迁安文化体育旅游惠民卡</div>
            </div>
            <div class="box11 common-box">
              <div class="year">2024</div>
              <div class="nr">发行</div>
              <div class="nr-two">葫芦岛文化旅游惠民卡</div>
            </div>
          </div>
        </div>
        <div class="center-box">
          <img src="../../../assets/images/bioaoti14.png" class="title-img" />
          <div class="img-box">
            <div
              class="item-box"
              :class="{ 'lan-box': (Math.floor(index / 4) + (index % 2)) % 2 === 0, 'hui-box': (Math.floor(index / 4) + (index % 2)) % 2 !== 0 }"
              v-for="(item, index) in ywList"
              :key="index"
            >
              <!-- :style="`animation-delay:${index * 0.3}s;`" -->
              <div>{{ item.one }}</div>
              <div>{{ item.two }}</div>
              <div>{{ item.three }}</div>
            </div>
          </div>
          <!-- <div class="img-box">
            <div class="lan-box" :class="{ 'fadeLeft-enter': isShow }">
              <div>秦皇岛</div>
              <div>旅游一卡通</div>
            </div>
            <div class="hui-box" :class="{ 'fadeLeft-enter': isShow }">
              <div>迁安</div>
              <div>文化体育旅游</div>
              <div>惠民卡</div>
            </div>
            <div class="lan-box" :class="{ 'fadeLeft-enter': isShow }">
              <div>葫芦岛</div>
              <div>文化体育旅游</div>
              <div>惠民卡</div>
            </div>
            <div class="hui-box" :class="{ 'fadeLeft-enter': isShow }">
              <div>山海关</div>
              <div>文化旅游</div>
              <div>十年卡</div>
            </div>
          </div>
          <div class="img-box-two">
            <div class="hui-box" :class="{ 'fadeLeft-enter': isShow }">
              <div>社区</div>
              <div>智慧养老</div>
            </div>
            <div class="lan-box" :class="{ 'fadeLeft-enter': isShow }">
              <div>物联网</div>
              <div>软件开发</div>
            </div>
            <div class="hui-box" :class="{ 'fadeLeft-enter': isShow }">
              <div>社区</div>
              <div>智慧洗护</div>
            </div>
            <div class="lan-box" :class="{ 'fadeLeft-enter': isShow }">
              <div>社区</div>
              <div>电子商场</div>
            </div>
          </div> -->
        </div>
        <div class="right-box">
          <img src="../../../assets/images/bioaoti15.png" class="qy-img" />
          <div class="fz-box">
            <div class="fz-top">
              <div class="fz-item" v-for="(item, index) in fzList" :key="index">
                <div class="count">
                  <span class="count-num">{{ item.count }}</span>
                  <span class="unit">{{ item.unit }}</span>
                </div>

                <div class="text">{{ item.text }}</div>
              </div>
            </div>
            <div class="fz-bottom">
              累计会员150w,年活跃会员30w+,70+景区合作。今后将进一步整合旅游资源,提高旅游服务水平,满足本地游客多元化、
              个性化需求,推动秦皇岛旅游业的高质量发展。同时将为秦皇岛 本地及合作单位提供定制化软件及硬件解决方案。并且自建的
              白鹭湾景区,提供码头、住宿、沙滩、餐饮、露营等设施,保证旅客 能够体验专业的服务。
            </div>
          </div>
        </div>
      </div>
      <img src="../../../assets/images/qiu5.png" class="img5" />
      <img src="../../../assets/images/qiu1.png" class="img1" />
      <img src="../../../assets/images/qiu2.png" class="img2" />
      <img src="../../../assets/images/qiu3.png" class="img3" />
      <img src="../../../assets/images/qiu4.png" class="img4" />
      <img src="../../../assets/images/lou.png" class="lou" />
      <img src="../../../assets/images/xian.png" class="xian" />
      <img src="../../../assets/images/feiji2.png" class="fj2" />
      <img src="../../../assets/images/feiji1.png" class="fj1" />
    </el-drawer>
  </div>
</template>

<script>
export default {
  props: {
    showDrw: Boolean
  },
  data() {
    return {
      // drawer: false,
      direction: 'ttb',
      url: require('@/assets/images/jianjie1.jpg'),
      fzList: [
        { count: '150', unit: 'w+', text: '会员累计' },
        { count: '70+', unit: '', text: '合作景区' },
        { count: '30', unit: 'w', text: '活跃会员' }
      ],
      ywList: [
        { one: '秦皇岛', two: '旅游一卡通', three: '' },
        { one: '迁安', two: '文化体育旅游', three: '惠民卡' },
        { one: '葫芦岛', two: '文化体育旅游', three: '惠民卡' },
        { one: '山海关', two: '文化旅游', three: '十年卡' },
        { one: '社区', two: '智慧养老', three: '' },
        { one: '物联网', two: '软件开发', three: '' },
        { one: '社区', two: '智慧洗护', three: '' },
        { one: '社区', two: '电子商场', three: '' }
      ],
      isShow: false
    }
  },
  watch: {
    showDrw(val) {
      this.drawer = val
      this.isShow = val
      if (val) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.showItem()
            this.showFzItem()
          }, 1000)
        })
      } else {
        this.removeItem()
      }
    }
  },

  methods: {
    handleClose(done) {
      this.drawer = false
      this.$emit('close', false)
      done()
    },
    showItem() {
      let list = document.querySelectorAll('.img-box>.item-box')

      list.forEach((item, index) => {
        item.classList.add('visible')
        item.style.transitionDelay = `${index * 0.3}s`
      })
    },
    showFzItem() {
      let list = document.querySelectorAll('.fz-item')

      list.forEach((item, index) => {
        item.classList.add('visibleTwo')
        item.style.transitionDelay = `${index * 0.5}s`
      })
    },
    removeItem() {
      let list = document.querySelectorAll('.img-box>.item-box')
      let listTwo = document.querySelectorAll('.fz-item')
      list.forEach((item) => {
        item.classList.remove('visible')
        // item.style.transitionDelay = `${index * 0.3}s`
      })
      listTwo.forEach((itemTwo) => {
        itemTwo.classList.remove('visibleTwo')
        // itemTwo.style.transitionDelay = `${index * 0.5}s`
      })
    }
  }
}
</script>
<style scoped>
.page {
  position: relative;
  z-index: 999;
  width: 100%;
  height: 100%;
}
.drw {
  position: relative;
}
.xian {
  position: absolute;
  top: 4%;
  left: 0%;
  width: 14%;
  height: 5%;
}
.fj2 {
  position: absolute;
  top: 28%;
  left: 0%;
  width: 30%;
  height: 20%;
  @media (min-height: 920px) {
    top: 23%;
  }
}
.fj1 {
  position: absolute;
  top: 6%;
  right: 13%;
  width: 35%;
  height: 20%;
  /* @media (min-width: 1920px) {
    top: 2%;
    right: 16%;
    width: 30%;
  } */
}
.box {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.logo {
  width: 10%;
  height: 24%;
}
.left {
  width: 64%;
  padding: 0% 5% 0;
  color: #231816;
  line-height: 40px;
  font-weight: 700;
  font-size: 22px;
}
/* 服务宗旨 */
.right {
  position: relative;
  width: 30%;
  padding: 2% 6% 2%;
  margin-left: 20px;
}
.zongzhi {
  position: absolute;
  left: 0;
  top: 0;
  width: 22%;
  height: 16%;
}
.content {
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  width: 200px;
  height: 200px;

  background: url('../../../assets//images/zongzhi.png') no-repeat;
  background-size: 100% 100%;
}
.zz-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.zz-img {
  position: absolute;
  left: -2%;
  width: 160px;
  height: 140px;
  animation-delay: 1s;
}
.zz-img-two {
  position: absolute;
  right: 23%;
  width: 160px;
  height: 140px;
  animation-delay: 2.5s;
}
.img5 {
  position: absolute;
  top: 0;
  left: 10%;
  width: 18%;
  height: 12%;
}
.img1 {
  position: absolute;
  top: 0;
  right: 20%;
  width: 8%;
  height: 9%;
}
.img2 {
  position: absolute;
  bottom: 10%;
  right: 0%;
  width: 8%;
  height: 40%;
}
.img3 {
  position: absolute;
  bottom: 0;
  right: 28%;
  width: 14%;
  height: 8%;
}
.img4 {
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 12%;
  height: 12%;
}
.lou {
  position: absolute;
  bottom: -2%;
  left: 25%;
  width: 10%;
  height: 20%;
}
.bottom-box {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1% 5% 0;
  color: #231816;
  font-weight: 700;
  height: 60%;
}
.title-img {
  width: 20%;
  height: 20%;
}
/* 发展历程 */
.left-box {
  width: 33%;

  padding-right: 120px;
}
.lc-img {
  position: absolute;
  top: 17%;
  left: 5%;
  width: 7%;
  height: 9%;

  @media (min-height: 920px) {
    top: 23%;

    height: 7%;
  }
}
.lc-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.lc-img-one {
  position: absolute;
  animation-delay: 0.5s;
}
.lc-img-two {
  position: absolute;
  animation-delay: 1s;
}
.lc-img-three {
  position: absolute;
  animation-delay: 1.5s;
}
.lc-img-four {
  position: absolute;
  right: 20%;
}
.lc-img-five {
  position: absolute;
  left: 29%;
}
.lc-img-six {
  position: absolute;
  right: 18%;
  /* animation-delay: 2.5s; */
}
.common-box {
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}
.year {
  color: #1b5b95;
  height: 12px;
}
.nr {
  height: 12px;
  font-size: 12px;
}
.nr-two {
  height: 12px;
  font-size: 12px;
}
.box1 {
  position: absolute;
  top: -150%;
  left: 42%;
  height: 40px;
}
.box2 {
  position: absolute;
  top: -120%;
  left: 21%;
}
.box3 {
  position: absolute;
  top: -39%;
  left: 15%;
}
.box4 {
  position: absolute;

  top: 45%;
  left: 5%;
}
.box5 {
  position: absolute;
  top: 140%;
  left: 16%;
}
.box6 {
  position: absolute;
  top: 240%;
  left: 20%;
}
.box7 {
  position: absolute;
  top: 290%;
  left: 35%;
}
.box8 {
  position: absolute;
  top: 300%;
  right: 10%;
}
.box9 {
  position: absolute;
  top: 183%;
  right: -12%;
}
.box10 {
  position: absolute;
  top: 58%;
  right: -12%;
}
.box11 {
  position: absolute;
  top: -66%;
  right: -6%;
}

/* 公司业务 */
.center-box {
  width: 33%;
}
.img-box {
  display: flex;
  flex-wrap: wrap;
}
.item-box {
  opacity: 0;
  transform: translateX(-30px);
  transition: all 1s ease-in-out;
}

.lan-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation-delay: 1s;
  /* font-size: 12px; */

  width: 130px;
  height: 130px;
  border-radius: 50%;
  color: #fff;
  margin: 1% 0;
  background: url(../../../assets/images/chanpin1.png) no-repeat center/cover;
}
.hui-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  animation-delay: 1.5s;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  color: #1b5b95;
  background: url(../../../assets/images/chanpin2.png) no-repeat center/cover;
}
/* 企业发展 */
.right-box {
  width: 33%;
  padding-left: 4%;
}
.qy-img {
  width: 130px;
  height: 40px;
}
.fz-box {
  padding-right: 20%;
}
.fz-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fz-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 80px;
  /* text-align: center;
  vertical-align: middle; */
  background: url(../../../assets/images/fazhan2.png) no-repeat;
  background-size: 100% 100%;
  opacity: 0;
  transform: translateY(30px);
  transition: all 1s ease-in-out;
}
.count {
  color: #1b5b95;
}
.count-num {
  font-size: 24px;
}
.unit {
  font-size: 10px;
}
.text {
  font-size: 12px;
  font-weight: bold;
}
.fz-bottom {
  text-indent: 2em;
  /* font-size: 12px; */
  background: url(../../../assets/images/fazhan1.png) no-repeat;
  background-size: 100% 100%;
  padding: 5%;
  margin-top: 5%;
  font-weight: 700;
}
.img-box-two {
  display: flex;
  margin-top: 20px;
}
::v-deep .el-drawer__header {
  text-align: center;
  font-size: 3em;
  color: #231816;
  font-weight: 700;
}
.visible {
  transition-delay: 1s;
  transform: translateX(0px);
  opacity: 1;
}
.visibleTwo {
  transition-delay: 1s;
  transform: translateY(0px);
  opacity: 1;
}
.fade-enter {
  animation: fadeIn 1.2s ease-in-out;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeLeft-enter {
  opacity: 1;
  animation: fadeLeft 2s ease-in-out;
}
@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    /* opacity: 1; */
    transform: translateX(0);
  }
}
</style>
